import { Box } from "@mui/material";

interface SpaceProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  inline?: number;
  inblock?: number;
}

const Space = (
  props: SpaceProps = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    inline: 0,
    inblock: 0,
  }
) => {
  const _left = props.left || props.inline;
  const _right = props.right || props.inline;
  const _top = props.top || props.inblock;
  const _bottom = props.bottom || props.inblock;
  return <Box sx={{ pt: _top, pl: _left, pb: _bottom, pr: _right }} />;
};

export default Space;
