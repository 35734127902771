import { Box } from "@mui/material";
import Header from "../../core/ui/components/Header";
import RsvpCard from "../../core/ui/components/RsvpCard";
import coverHomeBg from "../../core/assets/images/BGHomeCover.jpeg";
import PageCover from "../../core/ui/components/Cover/PageCover";
import Space from "../../core/ui/components/Space";
import HomeTimeline from "./components/HomeTimeline";
import homeContentEntity from "./entity/HomeContentEntity";
import { homeCoverEntity } from "./entity/HomeCoverEntity";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const HomeScreen = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header pageIndex={0} />
      <PageCover
        background={coverHomeBg}
        firstPart={homeCoverEntity.firstPart}
        secondPart={homeCoverEntity.secondPart}
        thirdPart={homeCoverEntity.thirdPart}
        subTitle={homeCoverEntity.subTitle}
      />
      <Space inblock={1} />
      <HomeTimeline timeline={homeContentEntity} />
      <Space inblock={1} />
      <RsvpCard />
      <Space inblock={1} />
    </Box>
  );
};

export default HomeScreen;
