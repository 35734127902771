import { Box } from "@mui/material";
import React, { useEffect } from "react";
import PageCover from "../../core/ui/components/Cover/PageCover";
import Header from "../../core/ui/components/Header";
import Space from "../../core/ui/components/Space";
import { detailsCoverEntity } from "./entity/DetailsCoverEntity";
import coverDetailsBg from "../../core/assets/images/DetailsBg.jpeg";
import DetailInfoView from "./components/DetailInfoView";
import { useLocation } from "react-router-dom";

const DetailScreen = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header pageIndex={2} />
      <PageCover
        background={coverDetailsBg}
        firstPart={detailsCoverEntity.firstPart}
        secondPart={detailsCoverEntity.secondPart}
        thirdPart={detailsCoverEntity.thirdPart}
        underTitle={detailsCoverEntity.underTitle}
        subTitle={detailsCoverEntity.subTitle}
      />
      <Space inblock={3} />
      <DetailInfoView />
    </Box>
  );
};

export default DetailScreen;
