import { GlobalStyles, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./core/navigation/router";
import theme from "./core/ui/theme/theme";
import i18n from "./core/locales/localization";
import { SecurityProvider } from "./core/infra/SecurityContext ";
import LandingScreen from "./features/landing/LandingScreen";

function App() {
  i18n.init();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          h1: { color: "white" },
          body: { backgroundColor: "#1F333C" },
        }}
      />
      <SecurityProvider>
        <LandingScreen />
      </SecurityProvider>
    </ThemeProvider>
  );
}

export default App;
