import { Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useRsvpContext } from "../RsvpContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ConfirmButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { rsvpData, handleOnConfirmSuccess, onConfirmSuccess } =
    useRsvpContext();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const {
      name,
      email,
      phoneDdi,
      phoneNumber,
      escorts: escortsNames,
      confirmedFriday,
      confirmedSaturday,
      confirmedSunday,
      transportation,
      message,
    } = rsvpData;
    const escorts = escortsNames ?? [];
    const escortsNumber = escorts?.length ?? 0;
    const confirmedFridayN = confirmedFriday ? 1 : 0;
    const confirmedSaturdayN = confirmedSaturday ? 1 : 0;
    const confirmedSundayN = confirmedSunday ? 1 : 0;

    const requestBody = {
      name,
      email,
      phoneDdi,
      phoneNumber,
      escortsNumber,
      escorts,
      confirmedFridayN,
      confirmedSaturdayN,
      confirmedSundayN,
      transportation,
      message,
    };
    try {
      setIsLoading(true);
      // await axios.post(
      //   "https://sheet.best/api/sheets/ce1698b4-6f0e-4b05-b2c2-0267910a5dcd",
      //   requestBody
      // );
      await axios.post(
        "https://sheet.best/api/sheets/e4f7e029-4271-46d0-8c98-bb83cce5351e",
        requestBody
      );
      setIsLoading(false);
      navigate("/success");
    } catch (error) {
      setIsLoading(false);
      handleOnConfirmSuccess(false);
      console.log(error);
    }
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Button
        variant="contained"
        color="secondary"
        disabled={isLoading || onConfirmSuccess}
        onClick={handleSubmit}
        style={{ position: "relative", width: "20vw" }}>
        {isLoading && (
          <CircularProgress
            size={24}
            style={{
              color: "#BC955E",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
        {isLoading
          ? translate("rsvpPage.content.confirmButton.loadingTitle")
          : translate("rsvpPage.content.confirmButton.title")}
      </Button>
    </Box>
  );
};

export default ConfirmButton;
