import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import landingPgImage from "../../../core/assets/images/CoverBG.jpeg";
import Space from "../../../core/ui/components/Space";
import theme from "../../../core/ui/theme/theme";

interface LpMobileViewProps {
  onClick: () => void;
}

const LpMobileView = (props: LpMobileViewProps) => {
  const { t: translate } = useTranslation();
  const { onClick } = props;
  const gradient =
    "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 16.67%, rgba(217, 217, 217, 0) 49.48%, #000000 100%)";
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={{
        flexDirection: "column",
        textAlign: "center",
        position: "relative",
        justifyContent: "center",
      }}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `${gradient}, url(${landingPgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: "50vw",
          bottom: isMobile ? "1%" : "1%",
          transform: "translate(-50%, -50%)",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "bloc",
            textAlign: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
              justifyContent: "center",
            }}>
            <Typography
              variant="h1"
              sx={{
                textShadow: "2px 2px 4px #000000",
              }}>
              Bea
            </Typography>
            <Typography
              variant="h1"
              sx={{
                textShadow: "2px 2px 4px #000000",
                color: theme.palette.secondary.main,
                marginInline: theme.spacing(4),
              }}>
              &
            </Typography>
            <Typography
              variant="h1"
              sx={{
                textShadow: "2px 2px 4px #000000",
              }}>
              Gui
            </Typography>
          </Box>
          <Typography
            variant="h4"
            sx={{
              textShadow: "2px 2px 4px #000000",
            }}>
            {translate("landingPage.pictureSubTitle")}
          </Typography>
        </Box>
        <Space top={1} />
        <Typography variant="h5">
          {translate("landingPage.subTitle")}
        </Typography>
        <Space inblock={2} />
        <Button color="secondary" variant="contained" onClick={() => onClick()}>
          {translate("landingPage.button")}
        </Button>
      </Box>
    </Box>
  );
};

export default LpMobileView;
