import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "Great Vibes",
      "Roboto",
      "Cormorant Garamond",
      "Lato",
    ].join(","),
    h1: {
      fontWeight: 500,
      fontFamily: "Great Vibes",
      fontSize: 80,
      letterSpacing: "-0.24px",
    },
    h2: {
      fontWeight: 300,
      fontFamily: "Cormorant Garamond",
      fontSize: 44,
      letterSpacing: "-0.24px",
      color: "white",
    },
    h3: {
      fontWeight: 500,
      fontFamily: "Great Vibes",
      fontSize: 50,
      letterSpacing: "-0.24px",
      color: "white",
    },
    h4: {
      fontWeight: 400,
      fontFamily: "Cormorant Garamond",
      fontSize: 30,
      letterSpacing: "-0.24px",
      color: "white",
    },
    h5: {
      fontWeight: 200,
      fontFamily: "Lato",
      fontSize: 20,
      letterSpacing: "-0.24px",
      color: "white",
    },
    h6: {
      fontWeight: 400,
      fontFamily: "Cormorant Garamond",
      fontSize: 28,
      letterSpacing: "-0.24px",
      color: "white",
    },
    subtitle1: {
      fontWeight: 400,
      fontFamily: "Cormorant Garamond",
      fontSize: 36,
      letterSpacing: "-0.24px",
      color: "white",
    },
    subtitle2: {
      fontWeight: 400,
      fontFamily: "Cormorant Garamond",
      fontSize: 24,
      letterSpacing: "-0.24px",
      color: "white",
    },

    body1: {
      fontWeight: 200,
      fontFamily: "Lato",
      fontSize: 16,
      letterSpacing: "-0.24px",
      color: "white",
    },
    body2: {
      fontWeight: 200,
      fontFamily: "Lato",
      fontSize: 12,
      letterSpacing: "-0.24px",
      color: "white",
    },
    overline: {
      fontWeight: 500,
    },
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1a1a1a",
          color: "#fff",

          borderRadius: "4px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "& .Mui-disabled": {
            borderWidth: 1,
            borderColor: "#FFF6E9",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFF6E9",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D0AE7C",
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: 0,
          height: "auto",
          backgroundColor: "transparent",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: "transparent",
        },
      },
    },
  },
  palette: {
    background: {
      default: "1F333C",
    },
    primary: {
      main: "#1F333C",
    },
    secondary: {
      main: "#BC955E",
    },
    text: {
      primary: "#000000",
      secondary: "#BC955E",
    },
    success: {
      main: "#55ab68",
      light: "#48C830",
    },
    warning: {
      main: "#f38713",
    },
    error: {
      main: "#ed4a3a",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;

export const textFieldStyles = {
  color: "white",
  "& .MuiOutlinedInput-outlined": {
    borderColor: "white",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
};
