import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRsvpContext } from "../RsvpContext";

const RsvpConfirmationAlert = () => {
  const { onConfirmSuccess, onConfirmError, clearError } = useRsvpContext();
  const { t: translate } = useTranslation();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    clearError();
  };

  if (onConfirmError)
    return (
      <Snackbar
        open={onConfirmSuccess}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {translate("rsvpPage.content.confirmationAlert.error")}
        </Alert>
      </Snackbar>
    );

  return (
    <Snackbar
      open={onConfirmSuccess}
      autoHideDuration={6000}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {translate("rsvpPage.content.confirmationAlert.success")}
      </Alert>
    </Snackbar>
  );
};

export default RsvpConfirmationAlert;
