import { Box, Container, Link, Typography, useMediaQuery } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";
import { dressCodeEntity } from "../entity/DetailsContentEntity";
import theme from "../../../core/ui/theme/theme";

const DetailDressCodeView = () => {
  const { t: translate } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Container>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Space top={4} />
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ fontWeight: 500 }}>
            {translate(dressCodeEntity.title)}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate(dressCodeEntity.description1)}
          </Typography>

          <Space inblock={1} />
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ color: theme.palette.secondary.main, width: "20%" }}>
              {translate(dressCodeEntity.women.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(dressCodeEntity.women.description1)}
                <Link
                  href={
                    "https://br.pinterest.com/lgpedroso/casamento-gui-bia-dress-code-mulheres/?invite_code=e5b7995e57034fd4b9ef5c5ed4eee489&sender=469430098567678345"
                  }
                  color="secondary"
                  target="_blank"
                  underline="always"
                  sx={{ ml: 1 }}>
                  {translate("detailsPage.content.dressCode.referencesTitle")}
                </Link>
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(dressCodeEntity.women.description2)}
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(dressCodeEntity.women.description3)}
              </Typography>
            </Container>
          </Box>
          <Space inblock={1} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              component="img"
              src="https://i.pinimg.com/564x/81/7b/c5/817bc5f68716100f7c0b96fe30a66410.jpg"
              alt="Exemplo de roupa esporte fino"
              sx={{
                width: { xs: "100%", md: "60%" },
                height: "auto",
                borderRadius: "1vh",
              }}
            />
          </Box>
          <Space inblock={2} />
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ color: theme.palette.secondary.main, width: "20%" }}>
              {translate(dressCodeEntity.men.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(dressCodeEntity.men.description1)}
                <Link
                  href={"https://pin.it/2tr9KJf"}
                  color="secondary"
                  target="_blank"
                  underline="always"
                  sx={{ ml: 1 }}>
                  {translate("detailsPage.content.dressCode.referencesTitle")}
                </Link>
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(dressCodeEntity.men.description2)}
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(dressCodeEntity.men.description3)}
              </Typography>
            </Container>
          </Box>
          <Space inblock={2} />
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              component="img"
              src="https://i.pinimg.com/564x/74/7c/2a/747c2a56a918464e4e34f623fb8deb39.jpg"
              alt="Exemplo de roupa esporte fino"
              sx={{
                width: { xs: "100%", md: "60%" },
                height: "auto",
                borderRadius: "1vh",
              }}
            />
          </Box>
          <Space inblock={2} />
          <Typography variant="body1">
            {translate(dressCodeEntity.description2)}
          </Typography>
          <Space inblock={1} />
          <Typography
            variant="body1"
            sx={{
              textDecoration: "underline",
              textDecorationColor: "white",
              textDecorationThickness: 1,
            }}>
            {translate(dressCodeEntity.details.title)}
          </Typography>
          <Space inblock={1} />
          <Container>
            <Typography variant="body1">
              {translate(dressCodeEntity.bottomDetails.description1)}
            </Typography>
          </Container>
          <Space inblock={1} />
          <Container>
            <Typography variant="body1">
              {translate(dressCodeEntity.bottomDetails.description2)}
            </Typography>
          </Container>
          <Space inblock={1} />
        </Box>
      </Container>
    </AnimationOnScroll>
  );
};

export default DetailDressCodeView;
