import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Box, Typography } from "@mui/material";
import Space from "../../../core/ui/components/Space";
import { useRsvpContext } from "../RsvpContext";
import { useTranslation } from "react-i18next";

const RsvpTransportationMethod = () => {
  const { updateRsvpTransportationMethod } = useRsvpContext();
  const { t: translate } = useTranslation();

  useEffect(() => {
    updateRsvpTransportationMethod("van");
  }, []);

  return (
    <Box>
      <Typography variant="subtitle2">
        {translate("rsvpPage.content.transportationToggle.title")}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="van"
          name="radio-buttons-group">
          <FormControlLabel
            value="van"
            color="secondary"
            control={
              <Radio
                color="secondary"
                onChange={(event) => {
                  if (event.target.checked) {
                    updateRsvpTransportationMethod(event.target.value);
                  }
                }}
              />
            }
            label={translate("rsvpPage.content.transportationToggle.vanOption")}
          />
          <FormControlLabel
            value="car"
            color="secondary"
            control={
              <Radio
                color="secondary"
                onChange={(event) => {
                  if (event.target.checked) {
                    updateRsvpTransportationMethod(event.target.value);
                  }
                }}
              />
            }
            label={translate("rsvpPage.content.transportationToggle.carOption")}
          />
          <FormControlLabel
            value="other"
            color="secondary"
            control={
              <Radio
                color="secondary"
                onChange={(event) => {
                  if (event.target.checked) {
                    updateRsvpTransportationMethod(event.target.value);
                  }
                }}
              />
            }
            label={translate(
              "rsvpPage.content.transportationToggle.otherOption"
            )}
          />
        </RadioGroup>
      </FormControl>
      <Space top={1} />
      <Typography
        variant="body1"
        sx={{
          textDecoration: "underline",
          textDecorationColor: "white",
          textDecorationThickness: 0.1,
        }}>
        {translate("rsvpPage.content.transportationToggle.disclaimer")}
      </Typography>
    </Box>
  );
};

export default RsvpTransportationMethod;
