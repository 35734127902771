import { Box, Container, Typography } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";
import { aditionalInfoEntity } from "../entity/DetailsContentEntity";

const DetailAditionalInfoView = () => {
  const { t: translate } = useTranslation();

  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Container>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Space top={4} />
          <Typography variant="subtitle1" color="secondary" sx={{ fontWeight: 500 }}>
            {translate(aditionalInfoEntity.title)}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate(aditionalInfoEntity.description1)}
          </Typography>
          <Space inblock={1} />
          <Typography variant="body1">
            {translate(aditionalInfoEntity.description2)}
          </Typography>
          <Space inblock={1} />
          <Typography variant="body1">
            {translate(aditionalInfoEntity.description3)}
          </Typography>
        </Box>
      </Container>
    </AnimationOnScroll>
  );
};

export default DetailAditionalInfoView;
