import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { textFieldStyles } from "../../../core/ui/theme/theme";
import { useRsvpContext } from "../RsvpContext";

const RsvpEmailTextField = () => {
  const { rsvpError, updateRsvpEmail, setRsvpErrorField } = useRsvpContext();
  const { t: translate } = useTranslation();

  function validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidInput = emailRegex.test(email);
    setRsvpErrorField(!isValidInput, "email");
  }

  return (
    <TextField
      id="outlined-basic"
      label={translate("rsvpPage.content.emailField.labelTitle")}
      variant="outlined"
      type="email"
      fullWidth
      color="secondary"
      InputProps={{
        style: textFieldStyles,
      }}
      onChange={(valueTyped) => {
        updateRsvpEmail(valueTyped.target.value);
      }}
      onBlur={(valueEntered) => {
        validateEmail(valueEntered.target.value);
      }}
      helperText={
        rsvpError.email
          ? translate("rsvpPage.content.emailField.hintLable")
          : ""
      }
      error={rsvpError.email}
    />
  );
};

export default RsvpEmailTextField;
