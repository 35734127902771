import React, { useState } from "react";
import { TextField, Button, MenuItem, Box } from "@mui/material";
import { textFieldStyles } from "../../../core/ui/theme/theme";
import Space from "../../../core/ui/components/Space";
import { useRsvpContext } from "../RsvpContext";
import { useTranslation } from "react-i18next";

interface TextFieldValue {
  value: string;
  label?: string;
}

const RsvpEscortsView: React.FC = () => {
  const { updateRsvpEscorts } = useRsvpContext();
  const [textFields, setTextFields] = useState<TextFieldValue[]>([]);
  const [hasEscorts, setEscorts] = useState(false);
  const { t: translate } = useTranslation();

  const handleAddTextField = () => {
    setTextFields([...textFields, { value: "" }]);
  };

  const handleTextFieldChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = [...textFields];
      newValues[index].value = event.target.value;
      setTextFields(newValues);
      const escorts = newValues.map((escort) => escort.value);
      updateRsvpEscorts(escorts);
    };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "yes") {
      setTextFields([{ value: "" }]);
      setEscorts(true);
    } else if (value === "no") {
      setTextFields([]);
      setEscorts(false);
    }
  };

  return (
    <>
      <TextField
        select
        defaultValue={
          hasEscorts
            ? translate("rsvpPage.content.escortsField.hasEscortsLabel")
            : translate("rsvpPage.content.escortsField.hasntEscortsLabel")
        }
        onChange={handleSelectChange}
        label={translate("rsvpPage.content.escortsField.hintLabel")}
        id="outlined-basic"
        variant="outlined"
        fullWidth
        color="secondary"
        InputProps={{
          style: textFieldStyles,
        }}>
        <MenuItem value="yes">
          {translate("rsvpPage.content.escortsField.hasEscortsLabel")}
        </MenuItem>
        <MenuItem
          value="no"
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#f0f0f0",
            },
          }}>
          {translate("rsvpPage.content.escortsField.hasntEscortsLabel")}
        </MenuItem>
      </TextField>
      {textFields.map((field, index) => (
        <Box>
          <Space top={1} />
          <TextField
            key={index}
            value={field.value}
            onChange={handleTextFieldChange(index)}
            label={translate("rsvpPage.content.escortsField.escortLabel")}
            fullWidth
            color="secondary"
            InputProps={{
              style: textFieldStyles,
            }}
          />
        </Box>
      ))}
      {textFields.length > 0 && (
        <Box>
          <Space inblock={1} />
          <Button
            onClick={handleAddTextField}
            variant="outlined"
            color="secondary">
            {translate("rsvpPage.content.escortsField.addNewEscort")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default RsvpEscortsView;
