import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Space from "../Space";
import PageCoverSubTitleProps from "./entity/PageCoverSubTitleProps";
import image from "../../../assets/images/logoSvg.svg";

const PageCoverSubTitle = (props: PageCoverSubTitleProps) => {
  const { title, subTitle, description } = props;
  return (
    <Container
      sx={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Space top={5} />
      <Typography variant="body2" sx={{ color: "white" }}>
        {title}
      </Typography>
      <Space top={1} />
      <Typography variant="h2" sx={{ color: "white" }}>
        {subTitle}
      </Typography>
      <Space top={2} />
      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
        }}>
        <img src={image} alt="Logo" style={{ maxWidth: "100%" }} />
      </Box>
      {description && (
        <Box>
          <Space top={2} />
          <Typography variant="body1" sx={{ color: "white" }}>
            {description}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default PageCoverSubTitle;
