import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../theme/theme";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import image from "../../assets/images/bfHeaderLogo.svg";
import Space from "./Space";
import TranslateToggleButton from "./TranslateToggleButton";
import { useTranslation } from "react-i18next";

const icons = [
  <HomeIcon sx={{ color: theme.palette.secondary.main }} />,
  <EventNoteIcon sx={{ color: theme.palette.secondary.main }} />,
  <MailIcon sx={{ color: theme.palette.secondary.main }} />,
  <FormatListBulletedIcon sx={{ color: theme.palette.secondary.main }} />,
];

interface HeaderProps {
  pageIndex: number;
}

const Header = (props: HeaderProps) => {
  const { t: translate } = useTranslation();
  const pages = [
    translate("navigation.home"),
    translate("navigation.schedule"),
    translate("navigation.details"),
    translate("navigation.rsvp"),
  ];
  const routes = ["/", "/schedule", "/details", "/rsvp"];
  const navigate = useNavigate();
  const { pageIndex } = props;
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [index, setIndex] = useState(pageIndex);
  const [showBottomNavigation, setShowBottomNavigation] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrollPos(currentScrollPos);
      const scrolledToBottom =
        window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
      setShowBottomNavigation(scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const opacity = Math.min(scrollPos / 100, 1);

  const toggleDrawer = () => {
    setDisplayDrawer(!displayDrawer);
  };

  const toggleNavigate = (index: number) => {
    navigate(routes[index]);
    setIndex(index);
    setDisplayDrawer(!displayDrawer);
  };

  const NavigationList = () => (
    <Box
      onKeyDown={toggleDrawer}
      sx={{ height: "100vh", backgroundColor: theme.palette.primary.main }}>
      <List>
        <Box sx={{ height: "7vh", backgroundColor: "transparent" }} />
        {[
          translate("navigation.home"),
          translate("navigation.schedule"),
          translate("navigation.details"),
          translate("navigation.rsvp"),
        ].map((text, index) => (
          <ListItem
            key={text + index}
            disablePadding
            onClick={() => toggleNavigate(index)}>
            <ListItemButton>
              <ListItemIcon>{icons[index]}</ListItemIcon>
              <Typography variant="body1" color="secondary" fontWeight={700}>
                {text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: `rgba(72, 96, 106, ${opacity})`,
          transition: "background-color 0.5s",
        }}>
        <Toolbar sx={{ position: "relative" }}>
          <Box
            sx={{
              top: 0,
              left: 0,
              maxWidth: "100%",
              display: "flex",
              height: { xs: "25px", md: "47px" },
              mt: 1,
              pl: displayDrawer ? "25vw" : "0",
              transition: "padding-left 0.2s",
            }}>
            {displayDrawer ? null : (
              <IconButton
                onClick={toggleDrawer}
                size="large"
                edge="start"
                aria-label="menu"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  color: "white",
                }}>
                <MenuIcon />
              </IconButton>
            )}
            {displayDrawer ? null : <img src={image} alt="Logo" />}
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                onClick={() => {
                  navigate(routes[index]);
                }}
                sx={{
                  my: 2,
                  mx: 3,
                  color:
                    pageIndex === index
                      ? theme.palette.secondary.main
                      : "white",
                  display: "block",
                }}>
                {page}
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              display: "flex",
              height: { xs: "30px", md: "47px" },
              mt: 1,
              p: 1,
            }}>
            <TranslateToggleButton />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{ display: { xs: "flex", md: "none" } }}
        open={displayDrawer}
        onClose={toggleDrawer}
        onAbort={toggleDrawer}>
        <NavigationList />
      </Drawer>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: theme.zIndex.drawer + 1,
          transition: "transform 0.5s",
          transform: `translateY(${showBottomNavigation ? "0" : "100%"})`,
        }}>
        <BottomNavigation
          showLabels
          value={index}
          sx={{
            display: { xs: "flex", md: "none", sm: "none" },
          }}
          onChange={(event, newValue) => {
            toggleNavigate(newValue);
          }}>
          <BottomNavigationAction
            label={translate("navigation.home")}
            icon={icons[0]}
            sx={{
              fontWeight: index === 0 ? 700 : 300,
              "&.Mui-selected": {
                color: theme.palette.secondary.main,
              },
            }}
          />
          <BottomNavigationAction
            label={translate("navigation.schedule")}
            icon={icons[1]}
            sx={{
              fontWeight: index === 1 ? 700 : 300,
              "&.Mui-selected": {
                color: theme.palette.secondary.main,
              },
            }}
          />
          <BottomNavigationAction
            label={translate("navigation.details")}
            icon={icons[2]}
            sx={{
              fontWeight: index === 2 ? 700 : 300,
              "&.Mui-selected": {
                color: theme.palette.secondary.main,
              },
            }}
          />
          <BottomNavigationAction
            label={translate("navigation.rsvp")}
            icon={icons[3]}
            sx={{
              fontWeight: index === 3 ? 700 : 300,
              "&.Mui-selected": {
                color: theme.palette.secondary.main,
              },
            }}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};
export default Header;
