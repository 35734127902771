import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useSecurityContext } from "../../infra/SecurityContext ";
import Space from "./Space";

interface PasswordModalProps {
  onConfirm: () => void;
  onClose: () => void;
}

const PasswordModal = (props: PasswordModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [hasInputWrongPassword, setHasInputWrongPassword] = useState(false);
  const { onConfirm, onClose } = props;
  const secret = "horizontes2023";

  const handleSubmit = () => {
    setIsLoading(true);
    if (password !== secret) {
      setIsLoading(false);
      setHasInputWrongPassword(true);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      onConfirm();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      PaperProps={{
        style: {
          backgroundColor: "#1F333C",
        },
      }}>
      <DialogTitle
        sx={{
          color: "#D9D9D9",
        }}>
        Senha de acesso
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: "#D9D9D9",
          }}>
          Por favor, digite a senha de acesso que se encontra na sua carta
          convite
        </DialogContentText>
        <TextField
          error={hasInputWrongPassword}
          autoFocus
          margin="dense"
          id="name"
          label="Senha de acesso"
          helperText={hasInputWrongPassword ? "Senha incorreta" : ""}
          type="password"
          fullWidth
          variant="standard"
          color="secondary"
          defaultValue="Sua senha de acesso"
          inputProps={{ style: { color: "#D9D9D9" } }}
          value={password}
          onChange={(valueTyped) => {
            setHasInputWrongPassword(false);
            setPassword(valueTyped.target.value);
          }}
        />
        <Space inblock={1} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Button
            onClick={() => handleSubmit()}
            fullWidth={true}
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ width: "50%" }}>
            {isLoading && (
              <CircularProgress
                size={24}
                style={{
                  color: "#BC955E",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
            {isLoading ? "Carregando.." : "Acessar"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordModal;
