import { Box, Container, Link, Typography } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";
import { dressUpEntity } from "../entity/DetailsContentEntity";

const DetailDressUpView = () => {
  const { t: translate } = useTranslation();

  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Container>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Space top={4} />
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ fontWeight: 500 }}>
            {translate(dressUpEntity.title)}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate(dressUpEntity.description1)}
          </Typography>
          <Space top={2} />
          <Container>
            <Typography variant="body1">
              {translate(dressUpEntity.hairSalons.salon1)}:
              <Link
                href={"https://wa.me/+5515997075763"}
                color="secondary"
                target="_blank"
                underline="always"
                sx={{ ml: 1 }}>
                +55 (15) 99707-5763
              </Link>
            </Typography>
            <Space top={1} />
            <Typography variant="body1">
              {translate(dressUpEntity.hairSalons.salon2)}:
              <Link
                href={"https://wa.me/+5515996158808"}
                color="secondary"
                target="_blank"
                underline="always"
                sx={{ ml: 1 }}>
                +55 (15) 99615-8808
              </Link>
            </Typography>
            <Space top={1} />
            <Typography variant="body1">
              {translate(dressUpEntity.hairSalons.salon3)}:
              <Link
                color="secondary"
                target="_blank"
                underline="always"
                sx={{ ml: 1 }}>
                +55 (15) 3282-6199
              </Link>
            </Typography>
          </Container>
        </Box>
      </Container>
    </AnimationOnScroll>
  );
};

export default DetailDressUpView;
