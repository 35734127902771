import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { API_KEY } from "../../../config";
import CircularProgress from "@mui/material/CircularProgress";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const center = {
  lat: -23.111931,
  lng: -47.740025,
};

const GoogleMapView = () => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: API_KEY ?? "" });

  if (!isLoaded) return <CircularProgress color="secondary" />;
  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
      <Marker position={center} />
    </GoogleMap>
  );
};

export default GoogleMapView;
