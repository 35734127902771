import { createBrowserRouter } from "react-router-dom";
import DetailScreen from "../../features/details/DetailScreen";
import HomeScreen from "../../features/home/HomeScreen";
import RsvpScreen from "../../features/rsvp/RsvpScreen";
import ScheduleScreen from "../../features/schedule/ScheduleScreen";
import ErrorScreen from "../../features/errorPage/ErrorScreen";
import LandingPage from "../../features/landing/LandingPage";
import SuccessView from "../../features/success/SuccessView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: "/details",
    element: <DetailScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: "/schedule",
    element: <ScheduleScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: "/rsvp",
    element: <RsvpScreen />,
    errorElement: <ErrorScreen />,
  },
  {
    path: "/success",
    element: <SuccessView />,
    errorElement: <ErrorScreen />,
  },
]);

export default router;
