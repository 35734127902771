import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";
import PageCoverTitleProps from "./entity/PageCoverTitleProps";

const LandingPageCover = (props: PageCoverTitleProps): React.ReactElement => {
  const { firstPart, secondPart, thirdPart, underTitle } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          left: "35vw",
          bottom: isMobile ? "2%" : "15%",
          transform: "translate(-50%, -50%)",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "bloc",
            textAlign: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
              justifyContent: "center",
            }}>
            <Typography
              variant="h1"
              sx={{
                textShadow: "2px 2px 4px #000000",
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "5rem" },
              }}>
              {firstPart}
            </Typography>
            <Typography
              variant="h1"
              sx={{
                textShadow: "2px 2px 4px #000000",
                color: theme.palette.secondary.main,
                marginInline: theme.spacing(4),
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "5rem" },
              }}>
              {secondPart}
            </Typography>
            <Typography
              variant="h1"
              sx={{
                textShadow: "2px 2px 4px #000000",
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "5rem" },
              }}>
              {thirdPart}
            </Typography>
          </Box>
          <Typography
            variant="h4"
            sx={{
              textShadow: "2px 2px 4px #000000",
            }}>
            {underTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageCover;
