import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography, Link } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Space from "../../../core/ui/components/Space";
import { giftsEntity } from "../entity/DetailsContentEntity";
import DetailsGiftOptionsView from "./DetailsGiftOptionsView";

const DetailGiftView = () => {
  const { t: translate } = useTranslation();

  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Container>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Space top={4} />
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ fontWeight: 500 }}>
            {translate(giftsEntity.title)}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate(giftsEntity.description1)}
          </Typography>
          <Space inblock={1} />
          <Typography variant="body1">
            {translate(giftsEntity.description2)}
          </Typography>
          <Typography variant="body1">
            {translate(giftsEntity.description3)}
          </Typography>
          <Space inblock={1} />
          <DetailsGiftOptionsView />
          <Space inblock={1} />
          <Typography variant="body1">
            {translate(giftsEntity.details.disclaimer)}
          </Typography>
        </Box>
      </Container>
    </AnimationOnScroll>
  );
};

export default DetailGiftView;
