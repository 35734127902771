import { Slide, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import BlurOverlayMaskView from "../../core/ui/components/BlurOverlayMaskView";
import PasswordModal from "../../core/ui/components/PasswordModal";
import LpMobileView from "./components/LpMobileView";
import LpWebView from "./components/LpWebView";
import { useNavigate } from "react-router-dom";
import { useSecurityContext } from "../../core/infra/SecurityContext ";

const LandingPage = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { handleAuthorization } = useSecurityContext();

  const [isOverlayMaskVisible, setIsOverlayMaskVisible] = useState(false);

  const onStartClick = () => {
    setIsOverlayMaskVisible(true);
  };

  const onStartClose = () => {
    setIsOverlayMaskVisible(false);
  };

  const navigateToHome = () => {
    handleAuthorization();
  };

  if (isMobile) {
    return (
      <Box sx={{ position: "relative" }}>
        <LpMobileView onClick={onStartClick} />;
        <Slide
          direction="up"
          in={isOverlayMaskVisible}
          mountOnEnter
          unmountOnExit>
          <Box>
            <BlurOverlayMaskView />
            <PasswordModal onConfirm={navigateToHome} onClose={onStartClose} />
          </Box>
        </Slide>
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <LpWebView onClick={onStartClick} />
      <Slide
        direction="up"
        in={isOverlayMaskVisible}
        mountOnEnter
        unmountOnExit>
        <Box>
          <BlurOverlayMaskView />
          <PasswordModal onConfirm={navigateToHome} onClose={onStartClose} />
        </Box>
      </Slide>
    </Box>
  );
};

export default LandingPage;
