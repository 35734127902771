import React, { createContext, ReactNode, useContext } from "react";
import { useSecurity } from "./useSecurity";

type ISecurityContext = {
  isAuthorized: boolean;
  handleAuthorization: () => void
};

const defaultContext: ISecurityContext = {
  isAuthorized: false,
  handleAuthorization: () => {}
};

export const SecurityContext = createContext(defaultContext);

export const SecurityConsumer = SecurityContext.Consumer;

export const SecurityProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const {
    isAuthorized,
    handleAuthorization
  } = useSecurity();

  return (
    <SecurityContext.Provider
      value={{
        isAuthorized,
        handleAuthorization
      }}>
      {children}
    </SecurityContext.Provider>
  );
};

export const useSecurityContext = () => useContext(SecurityContext);
