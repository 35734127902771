export const accommodationEntity = {
  title: "detailsPage.content.accommodation.title",
  description: "detailsPage.content.accommodation.description",
  tiete: {
    title: "detailsPage.content.accommodation.tiete.title",
    description1: "detailsPage.content.accommodation.tiete.description1",
    description2: "detailsPage.content.accommodation.tiete.description2",
    linkKeywords2: ["Robusti"],
    urls2: ["https://www.robustiplazzahotel.com.br/site/"],
    description3: "detailsPage.content.accommodation.tiete.description3",
    linkKeywords3: ["Zanata"],
    urls3: ["https://www.zanatahotel.com.br/site/"],
    description4: "detailsPage.content.accommodation.tiete.description4",
    linkKeywords4: ["Vale verde"],
    urls4: ["https://valeverdepalacehotel.com.br/"],
    contact: "detailsPage.content.accommodation.tiete.contact",
  },
  cerquilho: {
    title: "detailsPage.content.accommodation.cerquilho.title",
    description1: "detailsPage.content.accommodation.cerquilho.description1",
    description2: "detailsPage.content.accommodation.cerquilho.description2",
    linkKeywords1: ["Zanata"],
    urls1: ["https://www.zanatahotel.com.br/site/"],
  },
  boituva: {
    title: "detailsPage.content.accommodation.boituva.title",
    description1: "detailsPage.content.accommodation.boituva.description1",
    description2: "detailsPage.content.accommodation.boituva.description2",
  },

  portoFeliz: {
    title: "detailsPage.content.accommodation.portoFeliz.title",
    description1: "detailsPage.content.accommodation.portoFeliz.description1",
    description2: "detailsPage.content.accommodation.portoFeliz.description2",
    linkKeywords1: ["Porto Feliz Executive Hotel"],
    urls1: [
      "https://www.booking.com/hotel/br/transamerica-executive-porto-feliz.pt-br.html?aid=356980&label=gog235jc-1DCAMoIEILcG9ydG8tZmVsaXpILVgDaCCIAQGYAS24AQfIAQzYAQPoAQH4AQKIAgGoAgO4As3--6AGwAIB0gIkNmQ3ZTE4MjAtZDkyNC00NDRkLWJkNjAtNTdjYWU4ZDI1Mjgy2AIE4AIB&sid=08da9037d0b9db18a6a4540df5240583/",
    ],
  },
  houses: {
    title: "detailsPage.content.accommodation.houses.title",
    description1: "detailsPage.content.accommodation.houses.description",
  },
  camping: {
    title: "detailsPage.content.accommodation.camping.title",
    description1: "detailsPage.content.accommodation.camping.description",
  },

  notRecomended: {
    title: "detailsPage.content.accommodation.notRecomended.title",
    description1:
      "detailsPage.content.accommodation.notRecomended.description1",
    description2:
      "detailsPage.content.accommodation.notRecomended.description2",
  },
};

export const transportationEntity = {
  title: "detailsPage.content.transportation.title",
  van: {
    title: "detailsPage.content.transportation.van.title",
    description1: "detailsPage.content.transportation.van.description1",
    description2: "detailsPage.content.transportation.van.description2",
    description3: "detailsPage.content.transportation.van.description3",
  },
  car: {
    title: "detailsPage.content.transportation.car.title",
    description1: "detailsPage.content.transportation.car.description1",
    description2: "detailsPage.content.transportation.car.description2",
  },
};

export const dressCodeEntity = {
  title: "detailsPage.content.dressCode.title",
  description1: "detailsPage.content.dressCode.description1",
  description2: "detailsPage.content.dressCode.description2",
  details: {
    title: "detailsPage.content.dressCode.details.title",
    description1: "detailsPage.content.dressCode.details.description1",
    description2: "detailsPage.content.dressCode.details.description2",
  },
  men: {
    title: "detailsPage.content.dressCode.men.title",
    description1: "detailsPage.content.dressCode.men.description1",
    description2: "detailsPage.content.dressCode.men.description2",
    description3: "detailsPage.content.dressCode.men.description3",
  },
  women: {
    title: "detailsPage.content.dressCode.women.title",
    description1: "detailsPage.content.dressCode.women.description1",
    description2: "detailsPage.content.dressCode.women.description2",
    description3: "detailsPage.content.dressCode.women.description3",
  },
  bottomDetails: {
    title: "detailsPage.content.dressCode.bottomDetails.title",
    description1: "detailsPage.content.dressCode.bottomDetails.description1",
    description2: "detailsPage.content.dressCode.bottomDetails.description2",
  }

};

export const dressUpEntity = {
  title: "detailsPage.content.dressUp.title",
  description1: "detailsPage.content.dressUp.description1",
  hairSalons: {
    salon1: "detailsPage.content.dressUp.hairSalons.salon1",
    salon2: "detailsPage.content.dressUp.hairSalons.salon2",
    salon3: "detailsPage.content.dressUp.hairSalons.salon3",
  },
};

export const giftsEntity = {
  title: "detailsPage.content.gifts.title",
  description1: "detailsPage.content.gifts.description1",
  description2: "detailsPage.content.gifts.description2",
  description3: "detailsPage.content.gifts.description3",
  details: {
    title: "detailsPage.content.gifts.details.title",
    disclaimer: "detailsPage.content.gifts.details.description10",
    descriptions: [
      "detailsPage.content.gifts.details.description1",
      "detailsPage.content.gifts.details.description2",
      "detailsPage.content.gifts.details.description3",
      "detailsPage.content.gifts.details.description4",
      "detailsPage.content.gifts.details.description5",
      "detailsPage.content.gifts.details.description6",
      "detailsPage.content.gifts.details.description7",
      "detailsPage.content.gifts.details.description8",
      "detailsPage.content.gifts.details.description9",
    ],
    links: [
      "https://www.spinvisivel.org/",
      "https://www.tucca.org.br/",
      "https://www.institutoterrastore.com/",
      "https://projetotesourinha.org.br/",
      "https://adus.org.br/como-ajudar/?gclid=CjwKCAjwzuqgBhAcEiwAdj5dRuhzWL34YvqQBHXrRlo8LnJfyj5mUn5-mkv9S5iEDDyO__jZkHLm9hoCirsQAvD_BwE",
      "https://matmov.org.br/",
      "https://gastronomiaperiferica.com.br/",
      "http://www.crechecatapreta.com.br/",
      "https://oncafari.org/",
    ],
  },
};

export const aditionalInfoEntity = {
  title: "detailsPage.content.aditionalInfo.title",
  description1: "detailsPage.content.aditionalInfo.description1",
  description2: "detailsPage.content.aditionalInfo.description2",
  description3: "detailsPage.content.aditionalInfo.description3",
};
