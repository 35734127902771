import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import landingPgImage from "../../../core/assets/images/CoverBG.jpeg";
import LandingPageCover from "../../../core/ui/components/Cover/LandingPageCover";
import Space from "../../../core/ui/components/Space";
import { useTranslation } from "react-i18next";

interface LpWebViewProps {
  onClick: () => void;
}

const LpWebView = (props: LpWebViewProps) => {
  const { t: translate } = useTranslation();
  const { onClick } = props;
  const gradient =
    "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 16.67%, rgba(217, 217, 217, 0) 49.48%, #000000 100%)";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}>
      <Box
        sx={{
          position: "relative",
          justifyContent: "center",
          width: "70vw",
          height: "100vh",
        }}>
        <Box
          sx={{
            width: "70vw",
            height: "100vh",
            backgroundImage: `${gradient}, url(${landingPgImage})`,
            backgroundSize: "cover",
            objectFit: "cover",
          }}
        />
      </Box>
      <LandingPageCover
        firstPart="Bea"
        secondPart="&"
        thirdPart="Gui"
        underTitle={translate("landingPage.pictureSubTitle") ?? undefined}
      />

      <Box
        sx={{
          width: "30vw",
          flexDirection: "column",
          textAlign: "center",
        }}>
        <Typography variant="h3">{translate("landingPage.title")}</Typography>
        <Space top={1} />
        <Typography variant="h5">
          {translate("landingPage.subTitle")}
        </Typography>
        <Space inblock={2} />
        <Button color="secondary" variant="contained" onClick={() => onClick()}>
          {translate("landingPage.button")}
        </Button>
      </Box>
    </Box>
  );
};

export default LpWebView;
