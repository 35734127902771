import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import PageCover from "../../core/ui/components/Cover/PageCover";
import Header from "../../core/ui/components/Header";
import coverHomeBg from "../../core/assets/images/bg-water-landscape.jpeg";
import { rsvpCoverEntity } from "./entity/RsvpCoverEntity";
import Space from "../../core/ui/components/Space";
import FinishingCard from "../../core/ui/components/FinishingCard";
import ConfirmButton from "./component/ConfirmButton";
import { useLocation } from "react-router-dom";
import { RsvpProvider } from "./RsvpContext";
import RsvpNameTextField from "./component/RsvpNameTextField";
import RsvpEmailTextField from "./component/RsvpEmailTextField";
import RsvpMessageTextField from "./component/RsvpMessageTextField";
import RsvpConfirmationDays from "./component/RsvpConfirmationDays";
import RsvpTransportationMethod from "./component/RsvpTransportationMethod";
import RsvpEscortsView from "./component/RsvpEscortsView";
import RsvpPhone from "./component/RsvpPhone";
import RsvpConfirmationAlert from "./component/RsvpConfirmationAlert";
import SuccessPopover from "../success/SuccessPopover";

const RsvpScreen = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <RsvpProvider>
      <Box>
        <Header pageIndex={3} />
        <PageCover
          background={coverHomeBg}
          firstPart={rsvpCoverEntity.firstPart}
          secondPart={rsvpCoverEntity.secondPart}
          thirdPart={rsvpCoverEntity.thirdPart}
          subTitle={rsvpCoverEntity.subTitle}
        />
        <Container sx={{ flex: 1, maxWidth: "100%" }}>
          <Space inblock={1} />
          <RsvpNameTextField />
          <Space inblock={1} />
          <RsvpEmailTextField />
          <Space inblock={1} />
          <RsvpPhone />
          <Space inblock={1} />
          <RsvpEscortsView />
          <Space inblock={1} />
          <RsvpConfirmationDays />
          <Space inblock={1} />
          <RsvpTransportationMethod />
          <Space top={1} />
          <Space inblock={1} />
          <RsvpMessageTextField />
          <Space inblock={2} />
          <ConfirmButton />
          <Space inblock={8} />
          <RsvpConfirmationAlert />
        </Container>
      </Box>
    </RsvpProvider>
  );
};

export default RsvpScreen;
