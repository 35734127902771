import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography, Link } from "@mui/material";
import Space from "../../../core/ui/components/Space";
import { giftsEntity } from "../entity/DetailsContentEntity";

const DetailsGiftOptionsView = () => {
  const { t: translate } = useTranslation();

  return (
    <Box>
      {giftsEntity.details.descriptions.map((description, index) => (
        <Box key={index}>
          <Space inblock={1} />
          <Box>
            <Typography variant="body1">
              <Link
                href={giftsEntity.details.links[index]}
                color="secondary"
                target="_blank"
                underline="always">
                {translate(description)}
              </Link>
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DetailsGiftOptionsView;
