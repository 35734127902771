import { Box } from "@mui/material";
import PageCoverBackgroundProps from "./entity/PageCoverBackgroundProps";

const PageCoverBackground = (props: PageCoverBackgroundProps) => {
  const { image } = props;
  const gradient =
    "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 16.67%, rgba(217, 217, 217, 0) 49.48%, #000000 100%)";
  return (
    <Box
      sx={{
        width: "100%",
        height: "95vh",
        backgroundImage: `${gradient}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  );
};

export default PageCoverBackground;
