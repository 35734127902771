import React from "react";
import {
  Link,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";

interface LinkableTypographyProps {
  text: string;
  linkKeywords: string[];
  urls: string[];
}

const LinkableTypography = (props: LinkableTypographyProps): JSX.Element => {
  const { text, linkKeywords, urls } = props;

  const linkUrls = linkKeywords.reduce((acc, curr, index) => {
    acc[curr] = urls[index];
    return acc;
  }, {} as Record<string, string>);

  const parts = text
    .split(new RegExp(`(${linkKeywords.join("|")})`))
    .map((part, index) => {
      if (linkKeywords.includes(part)) {
        return (
          <Link
            key={`link-${index}`}
            href={linkUrls[part]}
            color="secondary"
            target="_blank"
            underline="always">
            {part}
          </Link>
        );
      }
      return part;
    });

  return <Typography>{parts}</Typography>;
};

export default LinkableTypography;
