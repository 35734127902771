import { Edit } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Space from "../../../core/ui/components/Space";
import theme from "../../../core/ui/theme/theme";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import BLIcon from "./BLIcon";

interface ScheduleItemProps {
  icon: string;
  title: string;
  subtitle: string;
  description1: string;
  description2?: string;
  description3?: string;
}

const ScheduleItem = (props: ScheduleItemProps) => {
  const { icon, title, subtitle, description1, description2, description3 } =
    props;
  const { t: translate } = useTranslation();

  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Box
        sx={{
          display: "flex",
          direction: "row",
          marginTop: "1rem",
        }}>
        <Space inline={1} />
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            sx={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
            {translate(title)}
          </Typography>
          <Typography variant="subtitle2">{translate(subtitle)}</Typography>
          <Space top={1} />
          <Typography variant="body1">{translate(description1)}</Typography>
          <Space inblock={1} />
          {description2 && (
            <Box>
              <Typography variant="body1">{translate(description2)}</Typography>
              <Space inblock={1} />
            </Box>
          )}
          {description3 && (
            <Box>
              <Typography variant="body1">{translate(description3)}</Typography>
              <Space inblock={1} />
            </Box>
          )}
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: theme.palette.secondary.main,
            }}
          />
          <Space inblock={1} />
        </Box>
      </Box>
    </AnimationOnScroll>
  );
};

export default ScheduleItem;
