import { Box } from "@mui/material";
import PageCoverProps from "./entity/PageCoverProps";
import PageCoverBackground from "./PageCoverBackground";
import PageCoverSubTitle from "./PageCoverSubTitle";
import { useTranslation } from "react-i18next";
import PageCoverTitle from "./PageCoverTitle";

const PageCover = (props: PageCoverProps) => {
  const { t: translate } = useTranslation();
  const { background, firstPart, secondPart, thirdPart, underTitle, subTitle } =
    props;

  const underTitleTranslated = underTitle ? translate(underTitle) : undefined; 
  const subtitleDescription = subTitle.description
    ? translate(subTitle.description!)
    : undefined;
  return (
    <Box>
      <Box sx={{ position: "relative", justifyContent: "center" }}>
        <PageCoverBackground image={background} />
        <PageCoverTitle
          firstPart={translate(firstPart)}
          secondPart={translate(secondPart)}
          thirdPart={translate(thirdPart)}
          underTitle={underTitleTranslated}
        />
      </Box>
      <PageCoverSubTitle
        title={translate(subTitle.title)}
        subTitle={translate(subTitle.subTitle)}
        description={subtitleDescription}
      />
    </Box>
  );
};

export default PageCover;
