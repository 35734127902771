export const rsvpCoverEntity = {
  firstPart: "rsvpPage.cover.titleInitialWord",
  secondPart: "rsvpPage.cover.titleMiddleWord",
  thirdPart: "rsvpPage.cover.titleFinalWord",
  subTitle: {
    title: "rsvpPage.info.title",
    subTitle: "rsvpPage.info.subTitle",
    description: "rsvpPage.info.description",
  },
};
