import { ButtonGroup, Button, useMediaQuery, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/localization";

const TranslateToggleButton = () => {
  const [selectedButton, setSelectedButton] = useState("pt");
  const { t: translate } = useTranslation();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonValue = event.currentTarget.value;
    setSelectedButton(buttonValue);
    i18n.changeLanguage(buttonValue);
  };

  return (
    <Box sx={{ direction: "flex", flexDirection: "row" }}>
      <ButtonGroup>
        <Button
          variant={selectedButton === "pt" ? "contained" : "outlined"}
          value="pt"
          color="secondary"
          onClick={handleButtonClick}
          sx={{ maxHeight: 30 }}>
          {translate("PT")}
        </Button>
        <Button
          variant={selectedButton === "en" ? "contained" : "outlined"}
          value="en"
          color="secondary"
          onClick={handleButtonClick}
          sx={{ maxHeight: 30 }}>
          {translate("EN")}
        </Button>
        {/* <Button
          variant={selectedButton === "de" ? "contained" : "outlined"}
          value="de"
          color="secondary"
          onClick={handleButtonClick}
          sx={{ maxHeight: 30 }}>
          {translate("DE")}
        </Button> */}
      </ButtonGroup>
    </Box>
  );
};

export default TranslateToggleButton;
