import { Box, Container, Typography } from "@mui/material";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";
import theme from "../../../core/ui/theme/theme";
import { transportationEntity } from "../entity/DetailsContentEntity";

const DetailTransportationView = () => {
  const { t: translate } = useTranslation();

  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Container>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Space top={4} />
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ fontWeight: 500 }}>
            {translate(transportationEntity.title)}
          </Typography>
          <Space inblock={1} />
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ color: theme.palette.secondary.main, width: "20%" }}>
              {translate(transportationEntity.van.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(transportationEntity.van.description1)}
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(transportationEntity.van.description2)}
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(transportationEntity.van.description3)}
              </Typography>
            </Container>
          </Box>
          <Space inblock={2} />
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ color: theme.palette.secondary.main, width: "20%" }}>
              {translate(transportationEntity.car.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(transportationEntity.car.description1)}
              </Typography>
              <Space top={1} />
              <Typography variant="body1">
                {translate(transportationEntity.car.description2)}
              </Typography>
            </Container>
          </Box>
        </Box>
      </Container>
    </AnimationOnScroll>
  );
};

export default DetailTransportationView;
