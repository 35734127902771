import { Box, useMediaQuery } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import HomeTimelineMobile from "./HomeTimelineMobile";
import HomeTimelineWebView from "./HomeTimelineWebView";

export interface HomeTimelineEntity {
  title: string;
  date: string;
  detail: string;
  image: string;
  photoDescription: string;
}

export interface HomeTimelineProps {
  timeline: HomeTimelineEntity[];
}

const HomeTimeline = (props: HomeTimelineProps) => {
  const { timeline } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box>
      {isMobile ? (
        <HomeTimelineMobile timeline={timeline} />
      ) : (
        <HomeTimelineWebView timeline={timeline} />
      )}
    </Box>
  );
};

export default HomeTimeline;
