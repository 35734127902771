import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { textFieldStyles } from "../../../core/ui/theme/theme";
import { useRsvpContext } from "../RsvpContext";

const RsvpPhoneNumberTextField = () => {
  const { rsvpError, updateRsvpPhoneNumber, setRsvpErrorField } =
    useRsvpContext();

  const { t: translate } = useTranslation();
  return (
    <TextField
      id="outlined-basic"
      label={translate("rsvpPage.content.phoneField.labelTitle")}
      variant="outlined"
      fullWidth
      color="secondary"
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      InputProps={{
        style: textFieldStyles,
      }}
      onChange={(valueTyped) => {
        updateRsvpPhoneNumber(valueTyped.target.value);
      }}
      onBlur={(valueEntered) => {
        setRsvpErrorField(valueEntered.target.value.trim() === "", "name");
      }}
      helperText={
        rsvpError.name ? translate("rsvpPage.content.phoneField.hintLable") : ""
      }
      error={rsvpError.name}
    />
  );
};

export default RsvpPhoneNumberTextField;
