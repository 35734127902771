import { Box, Zoom, Backdrop, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRsvpContext } from "../rsvp/RsvpContext";
import Confetti from "react-confetti";
import theme from "../../core/ui/theme/theme";
import image from "../../core/assets/images/bg-success-image.jpeg";

const SuccessPopover = () => {
  const [success, setSuccess] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleClose = () => {
    setSuccess(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(true);
    }, 200);
  }, []);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      }}
      open={success}
      onClick={handleClose}>
      <Confetti numberOfPieces={150} />
      <Zoom in={success}>
        <img
          src={image}
          alt="Logo"
          style={{
            objectFit: "contain",
            maxWidth: isMobile ? "100%" : "40%",
            height: "auto",
          }}
        />
      </Zoom>
    </Backdrop>
  );
};

export default SuccessPopover;
