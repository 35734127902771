import { Container } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";
import { HomeTimelineProps } from "./HomeTimeline";
import HomeTimelineDescription from "./HomeTimelineDescription";
import HomeTimelineImageBox from "./HomeTimelineImageBox";

const HomeTimelineMobile = (props: HomeTimelineProps) => {
  const { t: translate } = useTranslation();
  const { timeline } = props;

  return (
    <>
      {timeline.map((item, index) => (
        <AnimationOnScroll animateIn="animate__fadeIn">
          <Container key={item.title + index} sx={{ color: "white" }}>
            <Space top={5} />
            <HomeTimelineDescription
              title={translate(item.title)}
              date={translate(item.date)}
              description={translate(item.detail)}
            />
            <Space top={1} />
            <HomeTimelineImageBox image={item.image} description={translate(item.photoDescription)}/>
          </Container>
        </AnimationOnScroll>
      ))}
    </>
  );
};

export default HomeTimelineMobile;
