import React, { createContext, ReactNode, useContext } from "react";
import { RsvpConfirmationEntity, RsvpErrorEntity, useRsvp } from "./useRsvp";

type IRsvpContext = {
  rsvpData: RsvpConfirmationEntity;
  rsvpError: RsvpErrorEntity;
  onConfirmSuccess: boolean;
  onConfirmError: boolean;
  updateRsvpName: (name: string) => void;
  setRsvpNameError: (isInvalid: boolean) => void;
  updateRsvpEmail: (email: string) => void;
  updateRsvpPhoneNumber: (phoneNumber: string) => void;
  updateRsvpPhoneDdi: (phoneDdi: string) => void;
  updateRsvpEscorts: (names: string[]) => void;
  updateRsvpMessage: (message: string) => void;
  setRsvpErrorField: <T extends keyof RsvpErrorEntity>(
    isInvalid: boolean,
    field: T
  ) => void;
  updateRsvpConfirmationFriday: (isFriday: boolean) => void;
  updateRsvpConfirmationSaturday: (isSaturday: boolean) => void;
  updateRsvpConfirmationSunday: (isSunday: boolean) => void;
  updateRsvpTransportationMethod: (transportaion: string) => void;
  handleOnConfirmSuccess: (isSucess: boolean) => void;
  clearError: () => void;
};

const defaultContext: IRsvpContext = {
  onConfirmSuccess: false,
  onConfirmError: false,
  rsvpData: {
    name: "",
    email: "",
    phoneDdi: "+55",
    phoneNumber: "",
    escorts: [],
    confirmedFriday: true,
    confirmedSaturday: true,
    confirmedSunday: true,
    transportation: "van",
  },
  rsvpError: {
    name: false,
    email: false,
    phoneDdi: false,
    phoneNumber: false,
    escorts: false,
    presentOnFriday: false,
    presentOnSaturday: false,
    presentOnSunday: false,
    transportation: false,
  },
  updateRsvpName: (name: string) => {},
  setRsvpNameError: (isInvalid: boolean) => {},
  updateRsvpEmail: (email: string) => {},
  updateRsvpPhoneNumber: (phoneNumber: string) => {},
  updateRsvpPhoneDdi: (phoneDdi: string) => {},
  updateRsvpEscorts: (names: string[]) => {},
  updateRsvpMessage: (message: string) => {},
  setRsvpErrorField: <T extends keyof RsvpErrorEntity>(
    isInvalid: boolean,
    field: T
  ) => {},
  updateRsvpConfirmationFriday: (isFriday: boolean) => {},
  updateRsvpConfirmationSaturday: (isSaturday: boolean) => {},
  updateRsvpConfirmationSunday: (isSunday: boolean) => {},
  updateRsvpTransportationMethod: (transportaion: string) => {},
  handleOnConfirmSuccess: (isSucess: boolean) => {},
  clearError: () => {},
};

export const RsvpContext = createContext(defaultContext);

export const RsvpConsumer = RsvpContext.Consumer;

export const RsvpProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const {
    rsvpData,
    rsvpError,
    onConfirmSuccess,
    onConfirmError,
    updateRsvpName,
    setRsvpNameError,
    updateRsvpEmail,
    updateRsvpPhoneNumber,
    updateRsvpPhoneDdi,
    updateRsvpEscorts,
    updateRsvpMessage,
    setRsvpErrorField,
    updateRsvpConfirmationFriday,
    updateRsvpConfirmationSaturday,
    updateRsvpConfirmationSunday,
    updateRsvpTransportationMethod,
    handleOnConfirmSuccess,
    clearError,
  } = useRsvp();

  return (
    <RsvpContext.Provider
      value={{
        rsvpData,
        rsvpError,
        onConfirmSuccess,
        onConfirmError,
        updateRsvpName,
        setRsvpNameError,
        updateRsvpEmail,
        updateRsvpPhoneNumber,
        updateRsvpPhoneDdi,
        updateRsvpEscorts,
        updateRsvpMessage,
        setRsvpErrorField,
        updateRsvpConfirmationFriday,
        updateRsvpConfirmationSaturday,
        updateRsvpConfirmationSunday,
        updateRsvpTransportationMethod,
        handleOnConfirmSuccess,
        clearError,
      }}>
      {children}
    </RsvpContext.Provider>
  );
};

export const useRsvpContext = () => useContext(RsvpContext);
