import { Box, Typography } from "@mui/material";
import Space from "../../../core/ui/components/Space";

interface HomeTimelineImageBoxProps {
  image: string;
  description: string;
}

const HomeTimelineImageBox = (props: HomeTimelineImageBoxProps) => {
  const { image, description } = props;
  return (
    <Box sx={{ textAlign: "center" }}>
      <img
        src={image}
        alt={image + "timeline"}
        style={{
          width: "100%",
          maxHeight: "50vh",
          objectFit: "cover",
          borderRadius: "5px",
        }}
      />
      <Space top={1} />
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
    </Box>
  );
};

export default HomeTimelineImageBox;
