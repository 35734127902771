import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import theme from "../theme/theme";
import Space from "./Space";

const RsvpCard = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          navigate("/rsvp");
        }}
        sx={{
          width: "25%",
        }}>
        RSVP
      </Button>
      {isMobile && <Space inblock={10} />}
    </Container>
  );
};

export default RsvpCard;
