import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { textFieldStyles } from "../../../core/ui/theme/theme";
import { useRsvpContext } from "../RsvpContext";

const RsvpMessageTextField = () => {
  const {t: translate} = useTranslation()
  const { updateRsvpMessage } =
    useRsvpContext();
  return (
    <TextField
      id="outlined-multiline-static"
      label={translate("rsvpPage.content.messageField.labelTitle")}
      multiline
      rows={6}
      variant="outlined"
      fullWidth
      color="secondary"
      InputProps={{
        style: textFieldStyles,
      }}
      onChange={(valueTyped) => {
        updateRsvpMessage(valueTyped.target.value);
      }}
    />
  );
};

export default RsvpMessageTextField;
