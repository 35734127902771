export const detailsCoverEntity = {
  firstPart: "detailsPage.cover.titleInitialWord",
  secondPart: "detailsPage.cover.titleMiddleWord",
  thirdPart: "detailsPage.cover.titleFinalWord",
  underTitle: "detailsPage.cover.underTitle",
  subTitle: {
    title: "detailsPage.info.title",
    subTitle: "detailsPage.info.subTitle",
    description: "detailsPage.info.description",
  },
};
