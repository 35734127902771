import { TimelineConnector, TimelineDot, TimelineSeparator } from "@mui/lab";
import theme from "../../../core/ui/theme/theme";

const HomeTimelineSeparator = () => {
  return (
    <TimelineSeparator>
      <TimelineDot variant="outlined" color="secondary" />
      <TimelineConnector
        sx={{ backgroundColor: theme.palette.secondary.main }}
      />
    </TimelineSeparator>
  );
};

export default HomeTimelineSeparator;
