import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";
import GoogleMapView from "./GoogleMapView";

const DetailHeaderView = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t: translate } = useTranslation();

  const headerView = "detailsPage.content.cerimony";

  const address = "https://maps.app.goo.gl/tptaHz9gWc45FXMY7?g_st=ic";

  if (isMobile)
    return (
      <Container>
        <Box sx={{ color: "white" }}>
          <Typography variant="h2" color="secondary" sx={{ fontWeight: 500 }}>
            {translate("detailsPage.content.cerimony.title")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.description")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.description2")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.methods")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.method1")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.method2")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.finalWords")}
          </Typography>
          <Space top={5} />
          <Button
            variant="outlined"
            href={address}
            target="_blank"
            color="secondary"
            rel="noopener noreferrer"
            sx={{ width: "70%", alignSelf: "center" }}>
            {translate("detailsPage.content.cerimony.directionButton")}
          </Button>
        </Box>
        <Space top={5} />
        <GoogleMapView />
      </Container>
    );

  return (
    <Container>
      <Typography variant="h2" color="secondary" sx={{ fontWeight: 500 }}>
        {translate("detailsPage.content.cerimony.title")}
      </Typography>
      <Box sx={{ display: "flex", displayDirection: "row" }}>
        <Box sx={{ maxWidth: "40%", color: "white" }}>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.description")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.description2")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.methods")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.method1")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.method2")}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate("detailsPage.content.cerimony.finalWords")}
          </Typography>
          <Space top={5} />
          <Button
            variant="outlined"
            href={address}
            target="_blank"
            color="secondary"
            rel="noopener noreferrer">
            {translate("detailsPage.content.cerimony.directionButton")}
          </Button>
        </Box>
        <Space left={5} />
        <GoogleMapView />
      </Box>
    </Container>
  );
};

export default DetailHeaderView;
