import { Box, Typography } from "@mui/material";
import Space from "../../../core/ui/components/Space";

interface HomeTimelineDescriptionProps {
  title: string;
  date: string;
  description: string;
}

const HomeTimelineDescription = (props: HomeTimelineDescriptionProps) => {
  const { title, date, description } = props;
  return (
    <Box sx={{ textAlign: "start" }}>
      <Typography
        variant="subtitle1"
        color="secondary"
        sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{date}</Typography>
      <Space top={1} />
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
};

export default HomeTimelineDescription;
