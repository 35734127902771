import { Box } from "@mui/material";
import DetailAccomodationView from "./DetailAccomodationView";
import DetailAditionalInfoView from "./DetailAditionalInfoView";
import DetailDressCodeView from "./DetailDressCodeView";
import DetailDressUpView from "./DetailDressUpView";
import DetailGiftView from "./DetailGiftView";
import DetailTransportationView from "./DetailTransportationView";

const DetailSectionView = () => {
  return (
    <Box>
      <DetailAccomodationView />
      <DetailTransportationView />
      <DetailDressCodeView />
      <DetailDressUpView />
      <DetailGiftView />
      <DetailAditionalInfoView />
    </Box>
  );
};

export default DetailSectionView;
