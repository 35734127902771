import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Container } from "@mui/material";
import {
  scheduleContentEntity,
  ScheduleDayEnum,
} from "../entity/ScheduleContentEntity";
import ScheduleItem from "./ScheduleItem";
import { useTranslation } from "react-i18next";

const ScheduleTabs = () => {
  const [scheduleDay, setScheduleDay] = useState<ScheduleDayEnum>(
    ScheduleDayEnum.FRIDAY
  );
  const { t: translate } = useTranslation();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ScheduleDayEnum
  ) => {
    setScheduleDay(newValue);
  };

  return (
    <Container sx={{ width: "100%" }}>
      <Tabs
        value={scheduleDay}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary">
        <Tab
          value={ScheduleDayEnum.FRIDAY}
          label={translate("schedulePage.content.friday.button")}
        />
        <Tab
          value={ScheduleDayEnum.SATURDAY}
          label={translate("schedulePage.content.saturday.button")}
        />
        <Tab
          value={ScheduleDayEnum.SUNDAY}
          label={translate("schedulePage.content.sunday.button")}
        />
      </Tabs>
      {scheduleContentEntity
        .find((entity) => entity.day === scheduleDay)
        ?.content.map((entity) => (
          <ScheduleItem
            icon={entity.icon}
            title={entity.title}
            subtitle={entity.subtitle}
            description1={entity.description1}
            description2={entity.description2}
            description3={entity.description3}
          />
        ))}
    </Container>
  );
};

export default ScheduleTabs;
