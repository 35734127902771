import { HomeTimelineEntity } from "../components/HomeTimeline";
import timeline1 from "../../../core/assets/images/timeline1.jpeg";
import timeline2 from "../../../core/assets/images/timeline2.jpeg";
import timeline3 from "../../../core/assets/images/timeline3.jpeg";
import timeline4 from "../../../core/assets/images/timeline4.jpeg";
import timeline5 from "../../../core/assets/images/timeline5.jpeg";

const homeContentEntity: HomeTimelineEntity[] = [
  {
    title: "homePage.content.title1",
    date: "homePage.content.subtitle1",
    detail: "homePage.content.details1",
    image: timeline1,
    photoDescription: "homePage.content.photodescription1",
  },
  {
    title: "homePage.content.title2",
    date: "homePage.content.subtitle2",
    detail: "homePage.content.details2",
    image: timeline2,
    photoDescription: "homePage.content.photodescription2",
  },
  {
    title: "homePage.content.title3",
    date: "homePage.content.subtitle3",
    detail: "homePage.content.details3",
    image: timeline3,
    photoDescription: "homePage.content.photodescription3",
  },
  {
    title: "homePage.content.title4",
    date: "homePage.content.subtitle4",
    detail: "homePage.content.details4",
    image: timeline4,
    photoDescription: "homePage.content.photodescription4",
  },
  {
    title: "homePage.content.title5",
    date: "homePage.content.subtitle5",
    detail: "homePage.content.details5",
    image: timeline5,
    photoDescription: "homePage.content.photodescription5",
  },
];

export default homeContentEntity;
