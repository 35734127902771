import { Box, useMediaQuery } from "@mui/material";
import Space from "../../../core/ui/components/Space";
import RsvpCountryCodeTextfield from "./RsvpCountryCodeTextfield";
import RsvpPhoneNumberTextField from "./RsvpPhoneNumberTextField";

const RsvpPhone = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}>
      <RsvpCountryCodeTextfield />
      {isMobile ? <Space inblock={1} /> : <Space inline={1} />}
      <RsvpPhoneNumberTextField />
    </Box>
  );
};

export default RsvpPhone;
