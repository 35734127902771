import { useState } from "react";

export const useSecurity = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleAuthorization = () => {
    setIsAuthorized(true);
  };

  return {
    isAuthorized,
    handleAuthorization,
  };
};
