export enum ScheduleDayEnum {
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export interface ScheduleContentEntity {
  day: ScheduleDayEnum;
  content: ScheduleDetailContentEntity[];
}

export interface ScheduleDetailContentEntity {
  icon: string;
  title: string;
  subtitle: string;
  description1: string;
  description2?: string;
  description3?: string;
}

export const scheduleContentEntity: ScheduleContentEntity[] = [
  {
    day: ScheduleDayEnum.FRIDAY,
    content: [
      {
        icon: "meet",
        title: "schedulePage.content.friday.title",
        subtitle: "schedulePage.content.friday.subTitle",
        description1: "schedulePage.content.friday.description1",
        description2: "schedulePage.content.friday.description2",
        description3: "schedulePage.content.friday.description3",
      },
    ],
  },
  {
    day: ScheduleDayEnum.SATURDAY,
    content: [
      {
        icon: "reception",
        title: "schedulePage.content.saturday.reception.title",
        subtitle: "schedulePage.content.saturday.reception.subTitle",
        description1: "schedulePage.content.saturday.reception.description1",
      },
      {
        icon: "cerimony",
        title: "schedulePage.content.saturday.cerimony.title",
        subtitle: "schedulePage.content.saturday.cerimony.subTitle",
        description1: "schedulePage.content.saturday.cerimony.description1",
      },
      {
        icon: "lunch",
        title: "schedulePage.content.saturday.lunch.title",
        subtitle: "schedulePage.content.saturday.lunch.subTitle",
        description1: "schedulePage.content.saturday.lunch.description1",
      },
      {
        icon: "forro",
        title: "schedulePage.content.saturday.forro.title",
        subtitle: "schedulePage.content.saturday.forro.subTitle",
        description1: "schedulePage.content.saturday.forro.description1",
      },
      {
        icon: "party",
        title: "schedulePage.content.saturday.party.title",
        subtitle: "schedulePage.content.saturday.party.subTitle",
        description1: "schedulePage.content.saturday.party.description1",
        description2: "schedulePage.content.saturday.party.description2",
      },
      {
        icon: "after",
        title: "schedulePage.content.saturday.after.title",
        subtitle: "schedulePage.content.saturday.after.subTitle",
        description1: "schedulePage.content.saturday.after.description1",
      },
    ],
  },
  {
    day: ScheduleDayEnum.SUNDAY,
    content: [
      {
        icon: "brunch",
        title: "schedulePage.content.sunday.title",
        subtitle: "schedulePage.content.sunday.subTitle",
        description1: "schedulePage.content.sunday.description1",
        description2: "schedulePage.content.sunday.description2",
        description3: "schedulePage.content.sunday.description3",
      },
    ],
  },
];
