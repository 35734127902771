import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, Typography } from "@mui/material";
import { useRsvpContext } from "../RsvpContext";
import { useTranslation } from "react-i18next";
import Space from "../../../core/ui/components/Space";

const RsvpConfirmationDays = () => {
  const { t: translate } = useTranslation();
  const {
    updateRsvpConfirmationFriday,
    updateRsvpConfirmationSaturday,
    updateRsvpConfirmationSunday,
  } = useRsvpContext();

  const handleInitialConfirmation = () => {
    updateRsvpConfirmationFriday(true);
    updateRsvpConfirmationSaturday(true);
    updateRsvpConfirmationSunday(true);
  };

  useEffect(() => {
    handleInitialConfirmation();
  });

  return (
    <Box>
      <Typography variant="subtitle2">
        {translate("rsvpPage.content.dayPresenceCheckBox.title")}
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              color="secondary"
              onChange={(valueEntered) =>
                updateRsvpConfirmationFriday(valueEntered.target.checked)
              }
            />
          }
          label={translate("rsvpPage.content.dayPresenceCheckBox.friday")}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              color="secondary"
              onChange={(valueEntered) =>
                updateRsvpConfirmationSaturday(valueEntered.target.checked)
              }
            />
          }
          label={translate("rsvpPage.content.dayPresenceCheckBox.saturday")}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              color="secondary"
              onChange={(valueEntered) =>
                updateRsvpConfirmationSunday(valueEntered.target.checked)
              }
            />
          }
          label={translate("rsvpPage.content.dayPresenceCheckBox.sunday")}
        />
      </FormGroup>
      <Space top={1} />
      <Typography
        variant="body1"
        sx={{
          textDecoration: "underline",
          textDecorationColor: "white",
          textDecorationThickness: 0.1,
        }}>
        {translate("rsvpPage.content.dayPresenceCheckBox.disclaimer")}
      </Typography>
    </Box>
  );
};

export default RsvpConfirmationDays;
