import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
} from "@mui/lab";
import { Container } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import { HomeTimelineProps } from "./HomeTimeline";
import HomeTimelineDescription from "./HomeTimelineDescription";
import HomeTimelineImageBox from "./HomeTimelineImageBox";
import HomeTimelineSeparator from "./HomeTimelineSeparator";

const HomeTimelineWebView = (props: HomeTimelineProps) => {
  const { t: translate } = useTranslation();
  const { timeline } = props;
  return (
    <>
      {timeline.map((item, index) => (
        <AnimationOnScroll animateIn="animate__fadeIn">
          <Container key={item.title + index}>
            <Timeline key={index} position="alternate">
              <TimelineItem>
                <TimelineOppositeContent color="white">
                  {index % 2 === 0 ? (
                    <HomeTimelineImageBox image={item.image} description={translate(item.photoDescription)}/>
                  ) : (
                    <HomeTimelineDescription
                      title={translate(item.title)}
                      date={translate(item.date)}
                      description={translate(item.detail)}
                    />
                  )}
                </TimelineOppositeContent>
                <HomeTimelineSeparator />
                <TimelineContent color="white">
                  {index % 2 !== 0 ? (
                    <HomeTimelineImageBox image={item.image} description={translate(item.photoDescription)}/>
                  ) : (
                    <HomeTimelineDescription
                      title={translate(item.title)}
                      date={translate(item.date)}
                      description={translate(item.detail)}
                    />
                  )}
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Container>
        </AnimationOnScroll>
      ))}
    </>
  );
};

export default HomeTimelineWebView;
