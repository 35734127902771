import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import LinkableTypography from "../../../core/ui/components/LinkableTypography";
import Space from "../../../core/ui/components/Space";
import theme from "../../../core/ui/theme/theme";
import { accommodationEntity } from "../entity/DetailsContentEntity";

const DetailAccomodationView = () => {
  const { t: translate } = useTranslation();
  return (
    <AnimationOnScroll animateIn="animate__fadeIn">
      <Container>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Space top={4} />
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ fontWeight: 500 }}>
            {translate(accommodationEntity.title)}
          </Typography>
          <Space top={1} />
          <Typography variant="body1">
            {translate(accommodationEntity.description)}
          </Typography>
          <Space inblock={1} />
          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ color: theme.palette.secondary.main, width: "20%" }}>
              {translate(accommodationEntity.tiete.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(accommodationEntity.tiete.description1)}
              </Typography>
              <Space inblock={1} />
              <Typography variant="body1">
                <LinkableTypography
                  text={translate(accommodationEntity.tiete.description2)}
                  linkKeywords={accommodationEntity.tiete.linkKeywords2}
                  urls={accommodationEntity.tiete.urls2}
                />
                <Container>
                  <Space top={1} />
                  <Typography variant="body1">
                    Whatssap:
                    <Link
                      href={"https://wa.me/+5515996038450"}
                      color="secondary"
                      target="_blank"
                      underline="always"
                      sx={{ ml: 1 }}>
                      +5515996038450
                    </Link>
                  </Typography>
                  <Space top={1} />
                  <Typography variant="body1">
                    {translate(accommodationEntity.tiete.contact)}
                    <Link
                      href={"mailto:reservas@robustiplazzahotel.com.br "}
                      color="secondary"
                      target="_blank"
                      underline="always"
                      sx={{ ml: 1 }}>
                      reservas@robustiplazzahotel.com.br
                    </Link>
                  </Typography>
                </Container>
              </Typography>
              <Space inblock={1} />
              <Typography variant="body1">
                <LinkableTypography
                  text={translate(accommodationEntity.tiete.description3)}
                  linkKeywords={accommodationEntity.tiete.linkKeywords3}
                  urls={accommodationEntity.tiete.urls3}
                />
                <Container>
                  <Space top={1} />
                  <Typography variant="body1">
                    Whatssap:
                    <Link
                      href={"https://wa.me/+5515981160712"}
                      color="secondary"
                      target="_blank"
                      underline="always"
                      sx={{ ml: 1 }}>
                      +5515981160712
                    </Link>
                  </Typography>
                  <Space top={1} />
                  <Typography variant="body1">
                    {translate(accommodationEntity.tiete.contact)}
                    <Link
                      href={"mailto:contato@zanatahotel.com.br"}
                      color="secondary"
                      target="_blank"
                      underline="always"
                      sx={{ ml: 1 }}>
                      contato@zanatahotel.com.br
                    </Link>
                  </Typography>
                </Container>
              </Typography>
              <Space inblock={1} />
              <Typography variant="body1">
                <LinkableTypography
                  text={translate(accommodationEntity.tiete.description4)}
                  linkKeywords={accommodationEntity.tiete.linkKeywords4}
                  urls={accommodationEntity.tiete.urls4}
                />
                <Container>
                  <Space top={1} />
                  <Typography variant="body1">
                    Whatssap:
                    <Link
                      href={"https://wa.me/+5515997365016"}
                      color="secondary"
                      target="_blank"
                      underline="always"
                      sx={{ ml: 1 }}>
                      +5515997365016
                    </Link>
                  </Typography>
                  <Space top={1} />
                  <Typography variant="body1">
                    {translate(accommodationEntity.tiete.contact)}
                    <Link
                      href={"mailto:reservas@valeverdepalacehotel.com.br"}
                      color="secondary"
                      target="_blank"
                      underline="always"
                      sx={{ ml: 1 }}>
                      reservas@valeverdepalacehotel.com.br
                    </Link>
                  </Typography>
                </Container>
              </Typography>
            </Container>
          </Box>
          <Space inblock={2} />

          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ color: theme.palette.secondary.main, width: "20%" }}>
              {translate(accommodationEntity.houses.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(accommodationEntity.houses.description1)}
              </Typography>
            </Container>
          </Box>
          <Space inblock={2} />

          <Box
            sx={{
              display: "flex",
              displayDirection: "row",
            }}>
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{
                color: theme.palette.secondary.main,
                width: { xs: "25%", md: "20%" },
              }}>
              {translate(accommodationEntity.camping.title)}
            </Typography>
            <Space inblock={1} />
            <Container>
              <Typography variant="body1">
                {translate(accommodationEntity.camping.description1)}
              </Typography>
            </Container>
          </Box>
          <Space inblock={2} />

          <Typography
            variant="body1"
            sx={{
              textDecoration: "underline",
              textDecorationColor: "white",
              textDecorationThickness: 1,
            }}>
            {translate(accommodationEntity.notRecomended.title)}
          </Typography>
          <Space inblock={1} />
          <Container>
            <Typography variant="body1">
              • {translate(accommodationEntity.notRecomended.description1)}
            </Typography>
          </Container>
        </Box>
      </Container>
    </AnimationOnScroll>
  );
};

export default DetailAccomodationView;
