import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { textFieldStyles } from "../../../core/ui/theme/theme";
import { useRsvpContext } from "../RsvpContext";

const RsvpNameTextField = () => {
  const { rsvpError, updateRsvpName, setRsvpErrorField } = useRsvpContext();
  const { t: translate } = useTranslation();
  return (
    <TextField
      id="outlined-basic"
      label={translate("rsvpPage.content.nameField.labelTitle")}
      variant="outlined"
      fullWidth
      color="secondary"
      InputProps={{
        style: textFieldStyles,
      }}
      onChange={(valueTyped) => {
        updateRsvpName(valueTyped.target.value);
      }}
      onBlur={(valueEntered) => {
        setRsvpErrorField(valueEntered.target.value.trim() === "", "name");
      }}
      helperText={
        rsvpError.name ? translate("rsvpPage.content.nameField.hintLable") : ""
      }
      error={rsvpError.name}
    />
  );
};

export default RsvpNameTextField;
