import { useState } from "react";

export interface RsvpConfirmationEntity {
  name: string;
  email: string;
  phoneDdi: string;
  phoneNumber: string;
  escorts: string[];
  confirmedFriday: boolean;
  confirmedSaturday: boolean;
  confirmedSunday: boolean;
  transportation: string;
  message?: string;
}

export interface RsvpErrorEntity {
  name: boolean;
  email: boolean;
  phoneDdi: boolean;
  phoneNumber: boolean;
  escorts: boolean;
  presentOnFriday: boolean;
  presentOnSaturday: boolean;
  presentOnSunday: boolean;
  transportation: boolean;
}

export const useRsvp = () => {
  const [rsvpData, setRsvpData] = useState<RsvpConfirmationEntity>({
    confirmedFriday: true,
    confirmedSaturday: true,
    confirmedSunday: true,
  } as RsvpConfirmationEntity);
  const [rsvpError, setRsvpError] = useState<RsvpErrorEntity>(
    {} as RsvpErrorEntity
  );

  const [onConfirmSuccess, setOnConfirmSuccess] = useState(false);
  const [onConfirmError, setOnConfirmError] = useState(false);

  const updateRsvpName = (name: string) => {
    setRsvpData({ ...rsvpData, name: name });
  };
  const setRsvpNameError = (isInvalid: boolean) => {
    setRsvpError({ ...rsvpError, name: isInvalid });
  };
  const setRsvpErrorField = <T extends keyof RsvpErrorEntity>(
    isInvalid: boolean,
    field: T
  ) => {
    setRsvpError({ ...rsvpError, [field]: isInvalid });
  };
  const updateRsvpEmail = (email: string) => {
    setRsvpData({ ...rsvpData, email: email });
  };
  const updateRsvpPhoneDdi = (ddi: string) => {
    setRsvpData({ ...rsvpData, phoneDdi: ddi });
  };
  const updateRsvpPhoneNumber = (phone: string) => {
    setRsvpData({ ...rsvpData, phoneNumber: phone });
  };
  const updateRsvpEscorts = (names: string[]) => {
    setRsvpData({ ...rsvpData, escorts: names });
  };
  const updateRsvpConfirmationFriday = (isFriday: boolean) => {
    setRsvpData({ ...rsvpData, confirmedFriday: isFriday });
  };
  const updateRsvpConfirmationSaturday = (isSaturday: boolean) => {
    setRsvpData({ ...rsvpData, confirmedSaturday: isSaturday });
  };
  const updateRsvpConfirmationSunday = (isSunday: boolean) => {
    setRsvpData({ ...rsvpData, confirmedSunday: isSunday });
  };
  const updateRsvpTransportationMethod = (transportation: string) => {
    setRsvpData({ ...rsvpData, transportation: transportation });
  };
  const updateRsvpMessage = (message: string) => {
    setRsvpData({ ...rsvpData, message: message });
  };
  const handleOnConfirmSuccess = (isSucess: boolean) => {
    setOnConfirmError(!isSucess);
    setOnConfirmSuccess(isSucess);
  };
  const clearError = () => {
    setOnConfirmError(false);
    setOnConfirmSuccess(false);
  };

  return {
    rsvpData,
    rsvpError,
    onConfirmSuccess,
    onConfirmError,
    updateRsvpName,
    setRsvpNameError,
    updateRsvpEmail,
    updateRsvpPhoneNumber,
    updateRsvpPhoneDdi,
    updateRsvpEscorts,
    updateRsvpMessage,
    setRsvpErrorField,
    updateRsvpConfirmationFriday,
    updateRsvpConfirmationSaturday,
    updateRsvpConfirmationSunday,
    updateRsvpTransportationMethod,
    handleOnConfirmSuccess,
    clearError
  };
};
