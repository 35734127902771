import { Box } from "@mui/material";
import Space from "../../../core/ui/components/Space";
import RsvpCard from "../../../core/ui/components/RsvpCard";
import DetailSectionView from "./DetailSectionView";
import DetailHeaderView from "./DetailHeaderView";

const DetailInfoView = () => {
  return (
    <Box>
      <DetailHeaderView />
      <Space inblock={1} />
      <DetailSectionView />
      <Space inblock={4} />
      <RsvpCard />
      <Space inblock={1} />
    </Box>
  );
};

export default DetailInfoView;
