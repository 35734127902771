import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import SuccessPopover from "./SuccessPopover";
import Header from "../../core/ui/components/Header";

const SuccessView = () => {
  return (
    <Box>
      <Header pageIndex={0} />
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh">
          <SuccessPopover />
          <Typography variant="h4" align="center">
            Você já pode fechar a aba.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default SuccessView;
