import { Box } from "@mui/material";

const BlurOverlayMaskView = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(8px)",
        zIndex: 0,
      }}
    />
  );
};

export default BlurOverlayMaskView;
