import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./english/enTranslation.json";
import ptTranslation from "./portuguese/ptTranslation.json";
import deTranslation from "./germany/deTranslation.json";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      pt: { translation: ptTranslation },
      //de: { translation: deTranslation },
    },
    fallbackLng: "pt",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
