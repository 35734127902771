import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { useSecurityContext } from "../../core/infra/SecurityContext ";
import router from "../../core/navigation/router";
import LandingPage from "./LandingPage";

const LandingScreen = () => {
  const { isAuthorized } = useSecurityContext();

  if (isAuthorized) return <RouterProvider router={router} />;

  return <LandingPage />;
};

export default LandingScreen;
