import { Box } from "@mui/material";
import React, { useEffect } from "react";
import PageCover from "../../core/ui/components/Cover/PageCover";
import Header from "../../core/ui/components/Header";
import Space from "../../core/ui/components/Space";
import { scheduleCoverEntity } from "./entity/ScheduleCoverEntity";
import coverScheduleBg from "../../core/assets/images/CoverScheduleBG.jpeg";
import ScheduleTabs from "./components/ScheduleTab";
import RsvpCard from "../../core/ui/components/RsvpCard";
import { useLocation } from "react-router-dom";

const ScheduleScreen = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header pageIndex={1} />
      <PageCover
        background={coverScheduleBg}
        firstPart={scheduleCoverEntity.firstPart}
        secondPart={scheduleCoverEntity.secondPart}
        thirdPart={scheduleCoverEntity.thirdPart}
        subTitle={scheduleCoverEntity.subTitle}
      />
      <Space inblock={1} />
      <ScheduleTabs />
      <Space inblock={1} />
      <RsvpCard />
      <Space inblock={2} />
    </Box>
  );
};

export default ScheduleScreen;
